import HotelCard from "@/components/HotelCard";
import LayoutBase from "@/components/layouts/LayoutBase";
import SEOHead from "@/components/SEOHead";
import { Shell } from "@/components/Shell";
import { DEFAULT_LOCALE, I18_COMMON_NAMESPACES, I18N_NAMESPACES } from "@/constants/i18n";
import { ROUTES } from "@/constants/routes";
import { GetHotelsDocument, GetHotelsQuery, GetHotelsQueryVariables, useGetHotelsQuery } from "@/data-access/query/__generated__/getHotels";
import { initializeServerSideApolloClient } from "@/lib/apollo";
import { addApolloState } from "@/lib/apollo/server";
import { commonQueries } from "@/utils/commonQueries";
import { fetchAndLoadTranslations } from "@/utils/fetchAndLoadTranslations";
import { logger } from "@/utils/logger";
import { GetServerSidePropsContext } from "next";
import { useTranslation } from "next-i18next";
import { ReactElement } from "react";

const HotelsPage = () => {
  const { t } = useTranslation(I18N_NAMESPACES.COMMON, { keyPrefix: "hotels" });
  const { data } = useGetHotelsQuery({ errorPolicy: "all" });

  const { hotels } = data ?? {};

  return (
    <Shell variant="content" className="w-full space-y-6 px-4 pb-24 pt-12 xl:max-w-5xl">
      <SEOHead seoTitle={t("seo.title")} seoDescription={t("seo.description")} />
      <h1>{t("allHotels")}</h1>
      <div className="grid gap-x-8 gap-y-6 lg:grid-cols-2">{hotels?.map((hotel) => <HotelCard key={hotel.id} hotel={hotel} />)}</div>
    </Shell>
  );
};

export default HotelsPage;

const translationNamespaces = [...I18_COMMON_NAMESPACES];

export const getServerSideProps = async (context: GetServerSidePropsContext) => {
  try {
    const locale = context.locale ?? DEFAULT_LOCALE;

    const apolloClient = initializeServerSideApolloClient({ query: context.query, req: context.req, res: context.res, locale });

    const [translations, hotelsResult] = await Promise.all([
      fetchAndLoadTranslations({ apolloClient, translationNamespaces, locale }),
      apolloClient.query<GetHotelsQuery, GetHotelsQueryVariables>({
        query: GetHotelsDocument,
        errorPolicy: "all",
      }),
      ...commonQueries({ apolloClient }),
    ]);

    if (hotelsResult.errors) {
      logger(hotelsResult.errors, "gSSP");
    }

    return addApolloState(apolloClient, {
      props: {
        ...translations,
      },
    });
  } catch (error) {
    logger(error, "gSSP");

    return {
      redirect: {
        destination: ROUTES.SERVER_ERROR,
        permanent: false,
      },
    };
  }
};

HotelsPage.getLayout = function getLayout(page: ReactElement) {
  return <LayoutBase>{page}</LayoutBase>;
};
