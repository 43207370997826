import Image, { ImageProps } from "next/image";
import { useEffect, useState } from "react";
import { PLACEHOLDER_IMAGE } from "@/constants/common";
import { cn } from "@/utils/cn";

interface Props extends ImageProps {
  disableSmoothLoading?: boolean;
}

interface Props extends ImageProps {
  disableSmoothLoading?: boolean;
  disablePlaceholder?: boolean;
}

const OptimizedImage = ({ disableSmoothLoading, disablePlaceholder, className, src, ...props }: Props) => {
  const [error, setError] = useState<boolean | null>(null);

  useEffect(() => {
    setError(null);
  }, [src]);

  const imageProps: ImageProps = {
    ...props,
    src: error || !src ? PLACEHOLDER_IMAGE : src,
    onError: () => setError(true),
    className: cn({ "opacity-0 blur-2xl grayscale transition-opacity duration-300": !disableSmoothLoading }, className),
  };

  if (!disablePlaceholder) {
    imageProps.placeholder = "blur";
    imageProps.blurDataURL = PLACEHOLDER_IMAGE;
  }

  if (!disableSmoothLoading) {
    imageProps.onLoad = (e) => {
      const image = e.target as HTMLImageElement;
      image.classList.remove("opacity-0");
      image.classList.remove("grayscale");
      image.classList.remove("blur-2xl");
    };
  }

  return <Image {...imageProps} />;
};

export default OptimizedImage;
