import { createLucideIcon } from "lucide-react";

const OreaLocationIcon = createLucideIcon("orea-location", [
  [
    "path",
    {
      key: "path1",
      fill: "none",
      stroke: "currentColor",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: "M19.535 9.477c0 4.425-5.066 10.277-6.89 12.242a.88.88 0 0 1-1.29 0c-1.828-1.957-6.902-7.793-6.89-12.242C4.465 5.347 7.84 2 12 2s7.535 3.348 7.535 7.477Z",
      vectorEffect: "non-scaling-stroke",
    },
  ],
  [
    "path",
    {
      key: "path2",
      fill: "none",
      stroke: "currentColor",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: "M15.547 9.594a3.547 3.547 0 1 1-7.094 0A3.546 3.546 0 0 1 12 6.05a3.546 3.546 0 0 1 3.547 3.543Z",
      vectorEffect: "non-scaling-stroke",
    },
  ],
]);

export default OreaLocationIcon;
