import { SEO_IMAGE_SIZE, SEO_PLACEHOLDER_IMAGE } from "@/constants/seo";
import Head from "next/head";
import { ReactNode } from "react";

interface Props {
  seoTitle?: string | null;
  seoDescription?: string | null;
  mediaUrl?: string;
  children?: ReactNode;
}

const SEOHead = ({ seoTitle, seoDescription, mediaUrl, children }: Props) => {
  const imageUrl = mediaUrl || `${process.env.NEXT_PUBLIC_BASE_URL || "https://myorea.orea.cz"}${SEO_PLACEHOLDER_IMAGE}`;

  return (
    <Head>
      {seoTitle && <title>{seoTitle}</title>}
      {seoDescription && <meta name="description" content={seoDescription} key="desc" />}
      {seoTitle && <meta property="og:title" content={seoTitle} key="og-title" />}
      {seoDescription && <meta property="og:description" content={seoDescription} key="og-description" />}
      <meta property="og:image" content={imageUrl} key="og-image" />
      <meta property="og:image:width" content={SEO_IMAGE_SIZE.WIDTH} key="og-image-width" />
      <meta property="og:image:height" content={SEO_IMAGE_SIZE.HEIGHT} key="og-image-height" />
      {children}
    </Head>
  );
};

export default SEOHead;
